<template>
  <svg width="223" height="48" viewBox="0 0 223 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_147_12091)">
      <g clip-path="url(#clip1_147_12091)">
        <g clip-path="url(#clip2_147_12091)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M27.39 39.5674C27.7743 40.42 27.9657 41.3533 27.9657 42.3582H33.7057C33.6682 40.0975 33.2955 38.2459 32.5787 36.8019C31.8274 35.3385 30.9767 34.1992 30.0253 33.3809C28.9444 32.2819 27.6188 31.4636 26.0428 30.935C24.5214 30.4482 22.3596 30.2003 19.599 30.1735V30.16C17.5868 30.1585 16.1173 30.0645 15.1889 29.8778C14.2922 29.6867 13.5049 29.2984 12.8284 28.7086C12.1865 28.2681 11.6827 27.623 11.3157 26.7704C10.9314 25.9521 10.74 25.0054 10.74 23.9198C10.74 22.9149 10.9314 21.9816 11.3157 21.129C11.6827 20.3122 12.1865 19.6462 12.8284 19.1355C13.5049 18.6218 14.2922 18.253 15.1889 18.0216C16.123 17.7946 17.5868 17.6826 19.5831 17.6826H19.599V11.7246H19.5831C16.5807 11.7246 14.2749 11.9814 12.6629 12.4966C11.0869 13.0267 9.76125 13.8435 8.67889 14.9425C7.72894 15.7608 6.87686 16.9016 6.12698 18.3635C5.4102 19.8075 5.03742 21.6591 5 23.9198C5.03742 26.2552 5.4102 28.1486 6.12698 29.5911C6.87686 31.0127 7.72894 32.1191 8.67889 32.8956C9.76125 34.0708 11.0869 34.9085 12.6629 35.4028C14.1843 35.8507 16.3461 36.0792 19.1067 36.1046V36.121H19.1225C21.1189 36.121 22.5827 36.233 23.5168 36.4585C24.4135 36.6914 25.2008 37.0602 25.8772 37.5754C26.5206 38.0831 27.0229 38.7506 27.39 39.5674Z" fill="currentColor"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.946 14.6023C23.0283 15.7013 24.3525 16.5196 25.93 17.0497C27.4499 17.535 29.6117 17.7829 32.3738 17.8098V17.8232C34.3845 17.8247 35.8555 17.9202 36.7824 18.1054C37.6805 18.2965 38.4678 18.6848 39.1429 19.2761C39.7862 19.7151 40.2886 20.3602 40.6556 21.2128C41.0413 22.0311 41.2313 22.9793 41.2313 24.0634C41.2313 25.0683 41.0413 26.0016 40.6556 26.8542C40.2886 27.671 39.7862 28.3385 39.1429 28.8477C38.4678 29.3629 37.6805 29.7302 36.7824 29.9631C35.8483 30.1886 34.3845 30.3006 32.3882 30.3006H32.3738V36.2586H32.3882C35.392 36.2586 37.6964 36.0033 39.3084 35.4866C40.8844 34.958 42.21 34.1397 43.2924 33.0407C44.2424 32.2239 45.0944 31.0831 45.8443 29.6197C46.5611 28.1772 46.9339 26.3256 46.9713 24.0634C46.9339 21.728 46.5611 19.8346 45.8443 18.3936C45.0944 16.9691 44.2424 15.8641 43.2924 15.0876C42.21 13.9124 40.8844 13.0747 39.3084 12.5805C37.787 12.1325 35.6252 11.904 32.8646 11.8786V11.8622H32.8488C30.8524 11.8622 29.3886 11.7502 28.4545 11.5233C27.5578 11.2918 26.7705 10.9245 26.0941 10.4093C25.4521 9.90012 24.9484 9.23264 24.5813 8.41585C24.197 7.56321 24.0056 6.62994 24.0056 5.625H18.2656C18.303 7.88575 18.6773 9.73885 19.3926 11.1798C20.1439 12.6432 20.9946 13.7855 21.946 14.6023Z" fill="currentColor"/>
        </g>
      </g>
      <path d="M54.6797 13.8457H61.3107L66.9357 27.562H67.1953L72.8203 13.8457H79.4513V35.9995H74.2374V22.3914H74.0535L68.7314 35.8589H65.3996L60.0775 22.3157H59.8936V35.9995H54.6797V13.8457Z" fill="currentColor"/>
      <path d="M87.1704 36.2808C86.1104 36.2808 85.1692 36.1041 84.3471 35.7508C83.5322 35.3902 82.8868 34.8493 82.4108 34.1282C81.9421 33.3998 81.7077 32.4875 81.7077 31.3914C81.7077 30.4683 81.87 29.6895 82.1945 29.0548C82.519 28.4202 82.9661 27.9046 83.5358 27.508C84.1055 27.1113 84.7618 26.812 85.5046 26.6101C86.2474 26.401 87.0406 26.2604 87.8844 26.1883C88.8291 26.1017 89.5899 26.0116 90.1668 25.9178C90.7438 25.8169 91.162 25.6762 91.4216 25.4959C91.6885 25.3084 91.8219 25.0452 91.8219 24.7063V24.6522C91.8219 24.0969 91.6308 23.6678 91.2486 23.3649C90.8664 23.062 90.3507 22.9106 89.7017 22.9106C89.0022 22.9106 88.4397 23.062 88.0142 23.3649C87.5887 23.6678 87.3183 24.0861 87.2029 24.6197L82.3243 24.4467C82.4685 23.437 82.8399 22.5356 83.4385 21.7423C84.0442 20.9419 84.8736 20.3145 85.9265 19.8601C86.9865 19.3986 88.2594 19.1678 89.745 19.1678C90.8051 19.1678 91.7822 19.294 92.6765 19.5464C93.5707 19.7916 94.3495 20.1522 95.013 20.6282C95.6765 21.0969 96.1885 21.6738 96.5491 22.3589C96.9168 23.044 97.1007 23.8265 97.1007 24.7063V35.9995H92.1248V33.6846H91.995C91.6993 34.2471 91.3207 34.7231 90.8591 35.1125C90.4048 35.502 89.8676 35.794 89.2474 35.9887C88.6344 36.1834 87.9421 36.2808 87.1704 36.2808ZM88.8039 32.8193C89.3736 32.8193 89.8856 32.7039 90.3399 32.4731C90.8015 32.2423 91.1692 31.925 91.4433 31.5212C91.7173 31.1101 91.8543 30.6342 91.8543 30.0933V28.514C91.7029 28.5933 91.519 28.6654 91.3027 28.7303C91.0935 28.7952 90.8628 28.8565 90.6104 28.9142C90.3579 28.9719 90.0983 29.0224 89.8315 29.0657C89.5647 29.1089 89.3087 29.1486 89.0635 29.1846C88.5659 29.264 88.1404 29.3866 87.787 29.5524C87.4409 29.7183 87.1741 29.9346 86.9866 30.2015C86.8063 30.4611 86.7161 30.7712 86.7161 31.1318C86.7161 31.6798 86.9108 32.0981 87.3003 32.3866C87.6969 32.675 88.1981 32.8193 88.8039 32.8193Z" fill="currentColor"/>
      <path d="M104.801 31.6402L104.823 25.3337H105.558L110.231 19.3842H116.224L109.279 27.9082H107.884L104.801 31.6402ZM100.03 35.9995V13.8457H105.32V35.9995H100.03ZM110.35 35.9995L106.002 29.0981L109.485 25.3445L116.462 35.9995H110.35Z" fill="currentColor"/>
      <path d="M124.895 36.3133C123.157 36.3133 121.657 35.9707 120.395 35.2856C119.14 34.5933 118.174 33.6089 117.496 32.3325C116.825 31.0488 116.49 29.5236 116.49 27.7568C116.49 26.0404 116.829 24.5404 117.507 23.2568C118.185 21.9659 119.14 20.9635 120.373 20.2495C121.607 19.5284 123.06 19.1678 124.733 19.1678C125.915 19.1678 126.997 19.3517 127.978 19.7195C128.959 20.0873 129.806 20.6318 130.52 21.3529C131.234 22.0741 131.789 22.9647 132.186 24.0248C132.583 25.0777 132.781 26.2856 132.781 27.6486V28.9683H118.34V25.8962H127.859C127.852 25.3337 127.718 24.8325 127.459 24.3926C127.199 23.9527 126.842 23.6101 126.388 23.3649C125.941 23.1125 125.425 22.9863 124.841 22.9863C124.25 22.9863 123.72 23.1197 123.251 23.3866C122.782 23.6462 122.411 24.0032 122.137 24.4575C121.863 24.9046 121.718 25.413 121.704 25.9827V29.1089C121.704 29.7868 121.837 30.3818 122.104 30.8938C122.371 31.3986 122.75 31.7916 123.24 32.0729C123.73 32.3541 124.315 32.4947 124.992 32.4947C125.461 32.4947 125.887 32.4298 126.269 32.3C126.651 32.1702 126.979 31.9791 127.253 31.7267C127.527 31.4743 127.733 31.1642 127.87 30.7964L132.727 30.937C132.525 32.026 132.081 32.9743 131.396 33.782C130.718 34.5825 129.828 35.2063 128.724 35.6534C127.621 36.0933 126.345 36.3133 124.895 36.3133Z" fill="currentColor"/>
      <path d="M139.21 35.9995V32.9491L149.973 18.1943H139.221V13.8457H156.745V16.8962L145.981 31.651H156.734V35.9995H139.21Z" fill="currentColor"/>
      <path d="M165.959 36.3133C164.221 36.3133 162.721 35.9707 161.459 35.2856C160.204 34.5933 159.237 33.6089 158.56 32.3325C157.889 31.0488 157.554 29.5236 157.554 27.7568C157.554 26.0404 157.893 24.5404 158.57 23.2568C159.248 21.9659 160.204 20.9635 161.437 20.2495C162.67 19.5284 164.123 19.1678 165.796 19.1678C166.979 19.1678 168.061 19.3517 169.042 19.7195C170.022 20.0873 170.87 20.6318 171.584 21.3529C172.298 22.0741 172.853 22.9647 173.249 24.0248C173.646 25.0777 173.844 26.2856 173.844 27.6486V28.9683H159.403V25.8962H168.923C168.915 25.3337 168.782 24.8325 168.522 24.3926C168.263 23.9527 167.906 23.6101 167.451 23.3649C167.004 23.1125 166.489 22.9863 165.905 22.9863C165.313 22.9863 164.783 23.1197 164.314 23.3866C163.846 23.6462 163.474 24.0032 163.2 24.4575C162.926 24.9046 162.782 25.413 162.768 25.9827V29.1089C162.768 29.7868 162.901 30.3818 163.168 30.8938C163.435 31.3986 163.813 31.7916 164.304 32.0729C164.794 32.3541 165.378 32.4947 166.056 32.4947C166.525 32.4947 166.95 32.4298 167.332 32.3C167.715 32.1702 168.043 31.9791 168.317 31.7267C168.591 31.4743 168.796 31.1642 168.933 30.7964L173.79 30.937C173.588 32.026 173.145 32.9743 172.46 33.782C171.782 34.5825 170.891 35.2063 169.788 35.6534C168.685 36.0933 167.408 36.3133 165.959 36.3133Z" fill="currentColor"/>
      <path d="M181.531 26.5236V35.9995H176.241V19.3842H181.271V22.4346H181.455C181.823 21.4178 182.451 20.6209 183.338 20.044C184.225 19.4599 185.281 19.1678 186.507 19.1678C187.675 19.1678 188.689 19.431 189.547 19.9575C190.412 20.4767 191.083 21.2051 191.559 22.1426C192.042 23.0729 192.28 24.1618 192.273 25.4094V35.9995H186.983V26.4479C186.99 25.5248 186.756 24.8036 186.28 24.2844C185.811 23.7652 185.159 23.5056 184.322 23.5056C183.767 23.5056 183.276 23.6282 182.851 23.8733C182.433 24.1113 182.108 24.4539 181.877 24.901C181.654 25.3481 181.538 25.889 181.531 26.5236Z" fill="currentColor"/>
      <path d="M209.749 24.4575L204.892 24.5873C204.842 24.2411 204.705 23.9346 204.481 23.6678C204.257 23.3938 203.965 23.181 203.605 23.0296C203.251 22.8709 202.84 22.7916 202.372 22.7916C201.759 22.7916 201.236 22.9142 200.803 23.1594C200.378 23.4046 200.169 23.7363 200.176 24.1546C200.169 24.4791 200.298 24.7604 200.565 24.9983C200.839 25.2363 201.326 25.4274 202.025 25.5717L205.227 26.1774C206.886 26.4947 208.119 27.0212 208.927 27.7568C209.742 28.4923 210.153 29.4659 210.16 30.6774C210.153 31.8169 209.814 32.8084 209.143 33.6522C208.48 34.4959 207.571 35.1522 206.417 35.6209C205.263 36.0825 203.944 36.3133 202.458 36.3133C200.086 36.3133 198.214 35.8265 196.844 34.8529C195.481 33.8721 194.702 32.5596 194.507 30.9154L199.732 30.7856C199.848 31.3914 200.147 31.8529 200.63 32.1702C201.113 32.4875 201.73 32.6462 202.48 32.6462C203.158 32.6462 203.709 32.52 204.135 32.2676C204.56 32.0152 204.777 31.6798 204.784 31.2616C204.777 30.8866 204.611 30.5873 204.286 30.3637C203.962 30.133 203.453 29.9527 202.761 29.8229L199.862 29.2712C198.196 28.9683 196.956 28.4094 196.141 27.5945C195.326 26.7724 194.922 25.7267 194.929 24.4575C194.922 23.3469 195.218 22.3986 195.816 21.6125C196.415 20.8193 197.266 20.2135 198.369 19.7952C199.473 19.377 200.774 19.1678 202.274 19.1678C204.524 19.1678 206.298 19.6402 207.596 20.5849C208.894 21.5224 209.612 22.8133 209.749 24.4575Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_147_12091">
        <rect width="223" height="48" fill="currentColor"/>
      </clipPath>
      <clipPath id="clip1_147_12091">
        <rect width="42" height="42" fill="currentColor" transform="translate(5 3)"/>
      </clipPath>
      <clipPath id="clip2_147_12091">
        <rect width="42" height="36.75" fill="currentColor" transform="translate(5 5.625)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>

export default {
    name: "LogoComponent",
    data: () => ({
    }),
}
</script>