<template>
  <busy-indicator></busy-indicator>
  <div class="main-page-wrapper" :class="{'pb-80' : !hideMenu}">
    <nav-bar :transparent="transparentNavigation" :model="navigation" :hide-menu="hideMenu"/>
    <slot></slot>
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';
import BusyIndicator from "@/common/busy-indicator/BusyIndicator.vue";

export default {
  name: "PageWrapper",
  components: { BusyIndicator, NavBar },
  props: {
    transparentNavigation: Boolean,
    hideMenu: Boolean,
    navigation: Object,
  },
  data: () => ({}),
};
</script>
